import cn from 'classnames';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import capitalize from '@/services/capitalize';
import Icon from '@/components/atoms/Icon';
import Text from '@/components/atoms/Text';
import styles from './index.module.scss';
import pushToMicroSite from '@/utils/pushToMicroSite';
import Link from '@/components/atoms/Link';

const Chip = ({ slug = '', gender = '', href = '', simpleChip = false }) => {
  const chipClassName = cn({
    [styles.simpleChip]: simpleChip,
    [styles.chip]: !simpleChip
  });
  const router = useRouter();
  const storeId = useSelector((state) => state.store);

  const getHrefChip = () => {
    if (href) {
      return `${pushToMicroSite()}/${href}`;
    }
    if (!href) {
      const replacePath = '/';
      const pathToReplace = gender
        ? `/${gender}/`
        : `${`/k/${encodeURIComponent(slug)}/`}|/${encodeURIComponent(slug)}/`;

      let newPath = router.asPath.replace(
        new RegExp(pathToReplace),
        replacePath
      );
      const pathArray = newPath.split('/');
      if (
        storeId === process.env.NEXT_PUBLIC_STORE_ID &&
        pathArray.length === 3
      ) {
        pathArray.splice(1, 0, 'productos');
        newPath = pathArray.join('/');
      }
      if (
        storeId !== process.env.NEXT_PUBLIC_STORE_ID &&
        pathArray.length === 4
      ) {
        pathArray.splice(2, 0, 'productos');
        newPath = pathArray.join('/');
      }
      return newPath[0] !== '/' ? `/${newPath}` : newPath;
    }
  };
  return (
    <Link
      prefetch
      href={getHrefChip()}
      className={chipClassName}
    >
      <Text
        textColor={simpleChip ? 'black' : 'var(--color-primary)'}
        textSize="s"
      >
        {capitalize(slug || gender.replace('ino', 'iño'), '-')}
      </Text>
      {!simpleChip && (
        <div style={{ width: '.8rem' }}>
          <Icon
            center
            type="close"
            color="var(--color-primary)"
          />
        </div>
      )}
    </Link>
  );
};
Chip.propTypes = {
  slug: PropTypes.string,
  gender: PropTypes.string,
  href: PropTypes.string,
  simpleChip: PropTypes.bool
};

export default Chip;
